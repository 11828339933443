<template>
  <v-container grid-list-xl>
    <v-layout
      row
      wrap
    >
      <v-flex
        lg12
        sm12
        xs12
      >
        <v-card class="app-card app-form">
          <v-card-title class="app-card-title">
            <h4 class="text">
              <span>Detalhe Pagamento</span>
            </h4>
          </v-card-title>
          <v-card-text
            v-if="!detail.isLoading && detail.data.id"
            class="app-card-content"
          >
            <v-row>
              <v-col
                :lg="3"
                :md="3"
              >
                <em>Número Pagamento:</em>
                {{ detail.data.id }}
              </v-col>
              <v-col
                :lg="3"
                :md="3"
              >
                <em>Descrição Pagamento:</em>
                {{ detail.data.description }}
              </v-col>
              <v-col
                :lg="3"
                :md="3"
              >
                <em>Valor Pagamento:</em>
                {{ detail.data.value | currency }}
              </v-col>
              <v-col
                :lg="3"
                :md="3"
              >
                <em>Status:</em>
                {{ detail.data.status | statusPagamento }}
              </v-col>
              <v-col
                v-if="detail.data.solicitation.company"
                :lg="4"
                :md="4"
              >
                <em>Empresa:</em>
                {{ detail.data.solicitation.company.name }}
              </v-col>
              <v-col
                v-if="detail.data.solicitation.company"
                :lg="4"
                :md="4"
              >
                <em>CNPJ:</em>
                {{ detail.data.solicitation.company.cnpj }}
              </v-col>
              <v-col
                v-if="detail.data.solicitation.typeSolicitation"
                :lg="4"
                :md="4"
              >
                <em>Tipo Pedido:</em>
                {{ detail.data.solicitation.typeSolicitation.name }}
              </v-col>
              <v-col
                :lg="12"
                :md="12"
              >
                <v-btn
                  v-if="detail.data.externalPaymentType==='ticket' && detail.data.status!=='CANCELADO'"
                  depressed
                  color="success"
                >
                  <a
                    :href="detail.data.externalPaymentUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="white--text"
                  >
                    <v-icon>monetization_on</v-icon>Boleto
                  </a>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <section
            v-if="detail.isLoading"
            class="loading"
          >
            <v-progress-circular
              indeterminate
              color="success"
            />
            <span>Enviando informações ...</span>
          </section>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  export default {
    computed: {
      ...mapState('pagamento', ['detail'])
    },
    mounted() {
      this.initData();
    },
    destroyed() {
      this.clearResource();
    },
    methods: {
      async initData() {
        const {
          params: { id }
        } = this.$route;
        await this.getPagamento(id);
      },
      ...mapActions('pagamento', { getPagamento: 'SHOW' }),
      ...mapActions('pagamento', { clearResource: 'CLEAR_DETAIL' })
    },
  };
</script>
